import { baseApi } from "../baseApi";

export const documentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: (searchParams) => {
        const params = new URLSearchParams();

        if (searchParams.name) {
          params.append("name", encodeURIComponent(searchParams.name));
        }
        if (searchParams.folder) {
          params.append("folder", encodeURIComponent(searchParams.folder));
        }
        if (searchParams.global) {
          params.append("global", searchParams.global);
        }

        const queryString = params.toString() ? `?${params.toString()}` : "";
        return {
          url: `documents/all${queryString}`,
          method: "GET"
        };
      },
      providesTags: ["documents"]
    }),

    deleteDocument: builder.mutation({
      query: (id) => ({
        url: `documents/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["documents"]
    }),

    createDocument: builder.mutation({
      query: (formData) => ({
        url: `folderfile/document/create`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["documents"]
    }),

    getFolders: builder.query({
      query: (searchParams) => {
        let queryString = "";

        // Check if searchParams is provided and has properties to use
        if (searchParams) {
          const queryParts = [];

          // Append 'name' to the query string if it's provided
          if (searchParams.name) {
            queryParts.push(`name=${encodeURIComponent(searchParams.name)}`);
          }

          // Append 'parentFolder' to the query string if it's provided
          if (searchParams.parentFolder) {
            queryParts.push(
              `parentFolder=${encodeURIComponent(searchParams.parentFolder)}`
            );
          }

          if (searchParams.global) {
            queryParts.push(`global=${searchParams.global}`);
          }

          // Join all parts of the query string with '&'
          queryString = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
        }

        return {
          url: `folders/all${queryString}`,
          method: "GET"
        };
      },
      providesTags: ["folders"]
    }),

    // createFolder: builder.mutation({
    //   query: (formData) => ({
    //     url: `folders/create`,
    //     method: "POST",
    //     body: formData
    //   }),
    //   invalidatesTags: ["folders"]
    // }),

    // deleteFolder: builder.mutation({
    //   query: (id) => ({
    //     url: `folders/${id}`,
    //     method: "DELETE"
    //   }),
    //   invalidatesTags: ["folders"]
    // }),

    getSingleFolder: builder.query({
      query: (id) => ({
        url: `folders/${id}`,
        method: "GET"
      }),
      providesTags: ["folders"]
    }),

    userAccessFolder: builder.mutation({
      query: (formData) => ({
        url: `folders/updateacccess/${formData.id}`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["folders"]
    }),

    userAccessFile: builder.mutation({
      query: (formData) => ({
        url: `documents/updateacccess/${formData.id}`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["documents"]
    }),

    getSingleDocument: builder.query({
      query: (id) => ({
        url: `documents/${id}`,
        method: "GET"
      }),
      providesTags: ["documents"]
    }),

    // New Structure

    getFoldersDocuments: builder.query({
      query: (searchParams) => {
        return {
          url: `folderfile/all`,
          method: "GET"
        };
      },
      providesTags: ["folders"]
    }),

    deleteFile: builder.mutation({
      query: (data) => ({
        url: `folderfile/${data?.folderId}/document/${data?.documentId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["documents"]
    }),

    createFolder: builder.mutation({
      query: (formData) => ({
        url: `folderfile/folder/create`,
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["folders"]
    }),

    deleteFolder: builder.mutation({
      query: (id) => ({
        url: `folderfile/folder/delete/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["folders"]
    }),

    updateFolder: builder.mutation({
      query: (payload) => ({
        url: `folderfile/folder/update/${payload.id}`,
        method: "PATCH",
        body: payload.body
      }),
      invalidatesTags: ["folders"]
    }),

    updateDocument: builder.mutation({
      query: (payload) => ({
        url: `folderfile/document/update/${payload.folderId}/${payload.documentId}`,
        method: "PATCH",
        body: { name: payload.name }
      }),
      invalidatesTags: ["documents"] // Invalidate tags to refresh folder data
    }),

    updateDocumentState: builder.mutation({
      query: (payload) => ({
        url: `folderfile/document/state/update/${payload.folderId}/${payload.documentId}`,
        method: "PATCH",
        body: { name: payload.name }
      }),
      invalidatesTags: ["documents"] // Invalidate tags to refresh folder data
    })
  })
});

export const {
  useGetDocumentsQuery,
  useDeleteDocumentMutation,
  useCreateDocumentMutation,
  useGetFoldersQuery,
  useGetSingleFolderQuery,
  useUserAccessFolderMutation,
  useUserAccessFileMutation,
  useGetSingleDocumentQuery,
  useGetFoldersDocumentsQuery,
  useDeleteFileMutation,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useUpdateFolderMutation,
  useUpdateDocumentMutation,
  useUpdateDocumentStateMutation
} = documentsApi;
