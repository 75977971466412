import React, { useRef, useState } from "react";
import Marquee from "react-fast-marquee";

import { Row, Col, Card, Image, CardBody, Modal } from "react-bootstrap";

import IMAGES from "../../assets/images";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination } from "swiper/modules";

import { ArrowUp, ArrowDown } from "feather-icons-react";

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  PencilSquareIcon
} from "@heroicons/react/24/outline";

import moment from "moment";

import InstagramSlider from "../../components/Instagram";
import Weather from "../../components/Weather";
import Stocks from "../../components/Stocks";

import {
  useGetRecentNewsQuery,
  useGetEmployeesQuery,
  useGetBrochuresQuery,
  useGetMessagesQuery,
  useGetGalleryQuery,
  useGetAlertsQuery
} from "../../services/dashboard";

import { useGetRecentUpdatesQuery } from "../../services/updates";

import { Link, useNavigate } from "react-router-dom";
import Poll from "../../components/Poll";

import { checkPermissions } from "../../utils/checkPermissions";
import { useSelector } from "react-redux";

const Home = () => {
  const access_token =
    "IGQWRQVHBYOVdtbWtkTlRKdkp6RXdoclpmdzBmcDQ1V3FBTjMyNnNOcnRvZAnZAiM1lDRm81WUliaDZAtT211V1ZADQ0RkWkFJMG83alRhYVBwMTBEWlNYT2lZANldKNVhoaEhsWm5KeUVMMzVFMGNDVTV6VWpIaUJtS1EZD";

  const user = useSelector((state) => state.profile.user);
  const navigate = useNavigate();

  const { data: recentNewsData } = useGetRecentNewsQuery(undefined);
  const { data: employeesData } = useGetEmployeesQuery(undefined);
  const { data: brochuresData } = useGetBrochuresQuery(undefined);
  const { data: galleryData } = useGetGalleryQuery(undefined);
  const { data: messageData } = useGetMessagesQuery(undefined);
  const { data: alertData } = useGetAlertsQuery(undefined);
  const { data: updatesData } = useGetRecentUpdatesQuery();

  const [showNewsModal, setShowNewsModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showSpotlightModal, setShowSpotlightModal] = useState(false);
  const [selectedSpotlight, setSelectedSpotlight] = useState(null);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [showBrochureModal, setShowBrochureModal] = useState(false);
  const [showNewLatterModal, setShowNewLatterModal] = useState(false);
  const [selectedBrochure, setSelectedBrochure] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);

  const newsContainerRef = React.useRef(null);

  const scrollUp = () => {
    if (newsContainerRef.current) {
      newsContainerRef.current.scrollBy({ top: -100, behavior: "smooth" });
    }
  };

  const scrollDown = () => {
    if (newsContainerRef.current) {
      newsContainerRef.current.scrollBy({ top: 100, behavior: "smooth" });
    }
  };

  const handleShowNewsModal = (news) => {
    setSelectedNews(news);
    setShowNewsModal(true);
  };

  const handleCloseNewsModal = () => {
    setShowNewsModal(false);
    setSelectedNews(null);
  };

  const handleShowSpotlightModal = (spotlight) => {
    setSelectedSpotlight(spotlight);
    setShowSpotlightModal(true);
  };

  const handleCloseSpotlightModal = () => {
    setShowSpotlightModal(false);
    setSelectedSpotlight(null);
  };

  const handleShowGalleryModal = (gallery) => {
    setSelectedGallery(gallery);
    setShowGalleryModal(true);
  };

  const handleCloseGalleryModal = () => {
    setShowGalleryModal(false);
    setSelectedGallery(null);
  };

  const handleShowBrochureModal = (brochure) => {
    setSelectedBrochure(brochure);
    setShowBrochureModal(true);
  };

  const handleCloseBrochureModal = () => {
    setShowBrochureModal(false);
    setSelectedBrochure(null);
  };

  const handleShowNewsLatterModal = () => {
    setShowNewLatterModal(true);
  };

  const handleCloseNewsLatterModal = () => {
    setShowNewLatterModal(false);
  };

  const handleShowAlertModal = (alert) => {
    setSelectedAlert(alert);
    setShowAlertModal(true);
  };

  const handleCloseAlertModal = () => {
    setShowAlertModal(false);
    setSelectedAlert(null);
  };

  const formattedDate = (date) =>
    moment(date).format("MMMM DD YYYY [at] hh:mm A");

  const swiperRef = useRef(null);
  const swiperRef1 = useRef(null);
  const swiperRef2 = useRef(null);
  const swiperRef3 = useRef(null);

  const handleDownload = (e, fileUrl) => {
    e.stopPropagation();
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer";
    link.setAttribute("download", fileUrl.split("/").pop());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClick = (id) => {
    navigate(`/update-detail/${id}`);
  };

  const renderDescription = (description) => {
    if (!description) {
      return null; // Return null if description is undefined or empty
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = description.split(urlRegex);

    return parts?.map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          Click here
        </a>
      ) : (
        part
      )
    );
  };

  const canManageContent = checkPermissions(user, "add_content");

  return (
    <div>
      <h2 className="page-heading">
        Welcome to <span className="text-primary">CYGNUS Navigator</span>
      </h2>

      <Row className="g-3">
        <Col xs="12">
          <Card className="mb-0">
            <CardBody>
              <div className="d-flex align-items-center gap-2">
                <Image src={IMAGES.IconBell} className="icon-sm me-2" alt="" />
                <Marquee className="d-flex gap-3">
                  <div className="d-flex gap-4">
                    {alertData?.data?.map((alert) => (
                      <p
                        className="mb-0 d-flex align-items-center gap-1"
                        key={alert._id}
                        onClick={() => handleShowAlertModal(alert)}
                        style={{ cursor: "pointer" }}
                      >
                        <Image
                          src={IMAGES.IconNewsBullet}
                          className="icon-xxs"
                          alt=""
                        />
                        {alert?.title}
                      </p>
                    ))}
                  </div>
                </Marquee>
                {canManageContent && (
                  <Link to="dashboard/settings/alerts" className="edit-link">
                    <PencilSquareIcon className="icon-xs" />
                  </Link>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="7">
          <Row className="g-3 h-100 mb-xl-3 mb-0">
            <Col lg="6">
              <Card className="card-ceo-message h-100 mb-0">
                <Card.Title className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <Image
                      src={IMAGES.IconMessage}
                      className="icon-sm"
                      alt="Home"
                    />
                    Executives' Messages
                    {canManageContent && (
                      <Link
                        to="dashboard/settings/executives"
                        className="edit-link"
                      >
                        <PencilSquareIcon className="icon-xs" />
                      </Link>
                    )}
                  </div>
                  <div className="swiper-nav-btns">
                    <button onClick={() => swiperRef.current.slidePrev()}>
                      <ChevronLeftIcon className="icon-xs" />
                    </button>
                    <button onClick={() => swiperRef.current.slideNext()}>
                      <ChevronRightIcon className="icon-xs" />
                    </button>
                  </div>
                </Card.Title>
                <CardBody>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    freeMode={true}
                    pagination={{
                      clickable: true
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper h-100"
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                  >
                    {messageData?.data?.map((data) => (
                      <SwiperSlide>
                        <Row className="align-items-center g-3">
                          <Col md="auto">
                            <Image
                              src={data?.image}
                              className="object-fit-cover icon-xl rounded-circle"
                              alt="Home"
                            />
                          </Col>
                          <Col className="d-flex flex-column justify-content-between">
                            <div>
                              <h5 className="mt-0 mb-0">{data?.name}</h5>
                              <p className="mb-0 text-muted">{data?.title}</p>
                            </div>
                          </Col>
                        </Row>
                        <Card.Text className="mt-3">{data?.message}</Card.Text>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card className="h-100 mb-0">
                <Card.Title className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <Image
                      src={IMAGES.NewsDefaultImage}
                      className="icon-sm"
                      alt=""
                    />
                    Newsletters
                  </div>
                </Card.Title>
                <CardBody>
                  <div className="position-relative">
                    <Image
                      onClick={handleShowNewsLatterModal}
                      src={IMAGES.NewsLatterMain}
                      className="card-img w-100"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl="5" className="mb-3">
          <Card className="products-updates-card h-100 mb-0">
            <div className="d-flex justify-content-between align-items-center">
              <Card.Title className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-2">
                  <Image
                    src={IMAGES.IconUpdate}
                    className="icon-sm"
                    alt="Home"
                  />
                  Products & Alerts
                </div>
              </Card.Title>
              <Link className="link-to small text-muted" to="/updates">
                View all
              </Link>
            </div>

            <CardBody>
              <div
                className=""
                style={{ maxHeight: "100%", overflowY: "auto" }}
              >
                {updatesData?.data?.map((update) => (
                  <Row
                    className="gx-3 w-100"
                    key={1}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleClick(update?._id)}
                  >
                    <Col xs="auto">
                      {update?.image ? (
                        <Image
                          src={update?.image}
                          className="object-fit-contain rounded-3"
                          style={{
                            width: "50px",
                            height: "50px"
                          }}
                          alt="Home"
                        />
                      ) : (
                        <Image
                          src={IMAGES.NewsDefaultImage}
                          className="object-fit-cover rounded-3"
                          style={{ width: "50px", height: "50px" }}
                          alt="Home"
                        />
                      )}
                    </Col>
                    <Col>
                      <h6 className="mt-0 mb-1">{update?.title}</h6>

                      <p className="mb-1 small text-muted">
                        {formattedDate(update?.updatedAt)}
                      </p>
                      <hr />
                    </Col>
                  </Row>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="g-3">
        <Col xl="7">
          <Card>
            <Card.Title className="d-flex align-items-center">
              <div className="d-flex align-items-center gap-2">
                <Image src={IMAGES.IconEvents} className="icon-sm" alt="Home" />
                News & Events
                {canManageContent && (
                  <Link to="dashboard/settings/news" className="edit-link">
                    <PencilSquareIcon className="icon-xs" />
                  </Link>
                )}
              </div>
            </Card.Title>
            <CardBody>
              <div
                className="icon-hover"
                style={{ maxHeight: "500px", overflowY: "auto" }}
              >
                {recentNewsData?.data?.map((news, index) => (
                  <Row
                    className="gx-3 w-100"
                    key={index}
                    onClick={() => handleShowNewsModal(news)}
                  >
                    <Col xs="auto">
                      <Image
                        src={news?.firstImage}
                        className="object-fit-cover rounded-3"
                        style={{ width: "50px", height: "50px" }}
                        alt="Home"
                      />
                    </Col>
                    <Col>
                      <p className="mb-1 small text-muted">
                        {formattedDate(news?.date)}
                      </p>
                      <h6 className="mt-0 mb-1">{news?.title}</h6>
                      <hr />
                    </Col>
                  </Row>
                ))}
              </div>
            </CardBody>
          </Card>
          <Card>
            <Card.Title className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <Image src={IMAGES.IconSpotlights} className="icon-sm" alt="" />
                Spotlights
                {canManageContent && (
                  <Link to="dashboard/settings/spotlight" className="edit-link">
                    <PencilSquareIcon className="icon-xs" />
                  </Link>
                )}
              </div>
              <div className="swiper-nav-btns">
                <button onClick={() => swiperRef1.current.slidePrev()}>
                  <ChevronLeftIcon className="icon-xs" />
                </button>
                <button onClick={() => swiperRef1.current.slideNext()}>
                  <ChevronRightIcon className="icon-xs" />
                </button>
              </div>
            </Card.Title>
            <CardBody>
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true
                }}
                modules={[FreeMode, Navigation]}
                className="mySwiper"
                onSwiper={(swiper) => {
                  swiperRef1.current = swiper;
                }}
              >
                {employeesData?.data?.map((data, index) => (
                  <SwiperSlide
                    className="text-center"
                    style={{ width: "130px" }}
                    key={index}
                    onClick={() => handleShowSpotlightModal(data)}
                  >
                    <div>
                      <Image
                        src={data?.image}
                        className="rounded-circle mb-2 object-fit-cover"
                        style={{ width: "100px", height: "100px" }}
                        alt=""
                      />
                      <h6 className="my-0">{data?.name}</h6>
                      <p className="mb-0 small text-muted">{data?.position}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </CardBody>
          </Card>
          <Poll />
          <Card>
            <Card.Title className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <Image src={IMAGES.IconBrochures} className="icon-sm" alt="" />
                Brochures
                {canManageContent && (
                  <Link to="dashboard/settings/brochures" className="edit-link">
                    <PencilSquareIcon className="icon-xs" />
                  </Link>
                )}
              </div>
              <div className="swiper-nav-btns">
                <button onClick={() => swiperRef2.current.slidePrev()}>
                  <ChevronLeftIcon className="icon-xs" />
                </button>
                <button onClick={() => swiperRef2.current.slideNext()}>
                  <ChevronRightIcon className="icon-xs" />
                </button>
              </div>
            </Card.Title>
            <CardBody>
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true
                }}
                modules={[FreeMode, Navigation]}
                className="mySwiper"
                onSwiper={(swiper) => {
                  swiperRef2.current = swiper;
                }}
              >
                {brochuresData?.data?.map((data, index) => (
                  <SwiperSlide
                    className="icon-hover"
                    style={{ width: "150px" }}
                    key={index}
                    onClick={() => handleShowBrochureModal(data)}
                  >
                    <div>
                      <Image
                        src={data?.image}
                        className="rounded-3 mb-2 object-fit-cover w-100"
                        style={{ height: "200px" }}
                        alt=""
                      />
                      <div>
                        <h6 className="my-0">{data.name}</h6>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </CardBody>
          </Card>
        </Col>

        <Col xl="5">
          <Row>
            <Col>
              <InstagramSlider accessToken={access_token} />

              <Card>
                <Card.Title className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <Image
                      src={IMAGES.IconGallery}
                      className="icon-sm"
                      alt=""
                    />
                    Gallery
                    {canManageContent && (
                      <Link
                        to="dashboard/settings/gallery"
                        className="edit-link"
                      >
                        <PencilSquareIcon className="icon-xs" />
                      </Link>
                    )}
                  </div>
                  <div className="swiper-nav-btns">
                    <button onClick={() => swiperRef3.current.slidePrev()}>
                      <ChevronLeftIcon className="icon-xs" />
                    </button>
                    <button onClick={() => swiperRef3.current.slideNext()}>
                      <ChevronRightIcon className="icon-xs" />
                    </button>
                  </div>
                </Card.Title>
                <CardBody>
                  <div className="position-relative">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      freeMode={true}
                      pagination={{
                        clickable: true
                      }}
                      modules={[Navigation]}
                      className="mySwiper"
                      onSwiper={(swiper) => {
                        swiperRef3.current = swiper;
                      }}
                    >
                      {galleryData?.data?.map((data, index) => (
                        <SwiperSlide
                          key={index}
                          onClick={() => handleShowGalleryModal(data)}
                        >
                          <Row>
                            <Col>
                              {data.image.endsWith(".mp4") ? (
                                <video
                                  controls
                                  className="card-img object-fit-cover w-100"
                                  style={{ height: "var(--galleryHeight)" }}
                                >
                                  <source src={data.image} type="video/mp4" />
                                </video>
                              ) : (
                                <Image
                                  src={data.image}
                                  className="card-img object-fit-cover w-100"
                                  style={{ height: "var(--galleryHeight)" }}
                                  alt={data?.name}
                                />
                              )}
                            </Col>
                          </Row>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <Card.Title className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <Image src={IMAGES.IconStock} className="icon-sm" alt="" />
                    Daily News & Stocks Updates
                  </div>
                  <div className="swiper-nav-btns d-flex">
                    <button onClick={scrollUp}>
                      <ArrowUp className="icon-xs" />
                    </button>
                    <button onClick={scrollDown}>
                      <ArrowDown className="icon-xs" />
                    </button>
                  </div>
                </Card.Title>
                <CardBody>
                  <Stocks ref={newsContainerRef} />
                </CardBody>
              </Card>

              <div className="d-flex justify-content-between gap-3">
                <Card className="w-100">
                  <Card.Body className="d-flex align-items-center justify-content-between flex-wrap gap-2 icon-hover">
                    <div>
                      <Link
                        to={
                          "https://netorg4095625-my.sharepoint.com/:p:/g/personal/jibran_ghani_cygnuspay_com/ETlMgNmuzahMo5ixZ9TrHgcBy7CmLI0CVhROmoec608Flg?e=VAw71c&isSPOFile=1&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI1MC8yNDA1MzEwMTQyMSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
                        }
                        target="_blank"
                      >
                        <h6 className="my-0">
                          Marketing
                          <br />
                          Calendar
                        </h6>
                      </Link>
                    </div>
                    <Image
                      src={IMAGES.IconCalendarMarketing}
                      className="icon-md"
                      alt=""
                    />
                  </Card.Body>
                </Card>
                <Card className="w-100">
                  <Card.Body className="d-flex align-items-center justify-content-between flex-wrap gap-2 icon-hover">
                    <div>
                      <Link
                        to={
                          "https://netorg4095625-my.sharepoint.com/:w:/g/personal/rizwan_khan_cygnuspay_com/EevBtLzHX05GpaJlhComI7kBvN4QgkGefphxlcxo52Gcag"
                        }
                        target="_blank"
                      >
                        <h6 className="my-0">Holidays</h6>
                      </Link>
                    </div>
                    <Image
                      src={IMAGES.IconCalendarHoliday}
                      className="icon-md"
                      alt=""
                    />
                  </Card.Body>
                </Card>
                <Card className="w-100">
                  <Card.Body className="d-flex align-items-center justify-content-between flex-wrap gap-2 icon-hover">
                    <div>
                      <Link
                        to={
                          "https://docs.google.com/forms/d/e/1FAIpQLSdP0u05HizeRhpf0HBqTnqEp3PyG7tXs9dzIWwJ-Y4OU6BI8A/viewform?usp=sf_link"
                        }
                        target="_blank"
                      >
                        <h6 className="my-0">Feedback</h6>
                      </Link>
                    </div>
                    <Image src={IMAGES.IconThumb} className="icon-md" alt="" />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* News Modal */}
      <Modal
        show={showNewsModal}
        onHide={handleCloseNewsModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0" style={{ whiteSpace: "pre-wrap" }}>
          <h3 className="mt-0 ">{selectedNews?.title}</h3>
          <p className="mb-3">{formattedDate(selectedNews?.date)}</p>

          {selectedNews?.images?.map((image, index) => (
            <Image
              src={image}
              className="w-100 mb-3 object-fit-cover rounded-3"
              alt={image}
            />
          ))}
          <p className="mb-0 mb-3">
            {renderDescription(selectedNews?.description)}
          </p>
        </Modal.Body>
      </Modal>

      {/* Spotlight Modal */}
      <Modal
        show={showSpotlightModal}
        onHide={handleCloseSpotlightModal}
        centered
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="text-center">
            <Image
              src={selectedSpotlight?.image}
              alt={selectedSpotlight?.name}
              className="rounded-circle mb-3 object-fit-cover"
              style={{ width: "200px", height: "200px" }}
            />
            <h3 className="mt-0 mb-1">{selectedSpotlight?.name}</h3>
            <p className="text-muted">{selectedSpotlight?.position}</p>
          </div>

          <p className="text-center mb-0">{selectedSpotlight?.message}</p>
        </Modal.Body>
      </Modal>

      {/* Gallery Modal */}
      <Modal
        show={showGalleryModal}
        onHide={handleCloseGalleryModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 text-center">
          <h3 className="mt-0 mb-3">{selectedGallery?.name}</h3>

          {selectedGallery?.image.endsWith(".mp4") ? (
            <video
              controls
              className="w-100 object-fit-contain rounded-3"
              style={{ maxHeight: "600px" }}
            >
              <source src={selectedGallery?.image} type="video/mp4" />
            </video>
          ) : (
            <Image
              src={selectedGallery?.image}
              className="w-100 object-fit-contain rounded-3"
              style={{ maxHeight: "600px" }}
              alt={selectedGallery?.image}
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Brochure Modal */}
      <Modal
        show={showBrochureModal}
        onHide={handleCloseBrochureModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 text-center">
          <h3 className="mt-0 mb-3">{selectedBrochure?.name}</h3>
          <Image
            src={selectedBrochure?.image}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt={selectedBrochure?.name}
          />
        </Modal.Body>
      </Modal>

      {/* Alert Modal */}
      <Modal show={showAlertModal} onHide={handleCloseAlertModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedAlert?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{selectedAlert?.description}</Modal.Body>
      </Modal>

      {/* NewsLatter Modal */}
      <Modal
        show={showNewLatterModal}
        onHide={handleCloseNewsLatterModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Newsletter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 text-center">
          <Image
            src={IMAGES.NewsLatter1}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter2}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter3}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter4}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter5}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter6}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter7}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter8}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter9}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter10}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter11}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
          <Image
            src={IMAGES.NewsLatter12}
            className="w-100 mb-3 object-fit-cover rounded-3"
            style={{ height: "auto" }}
            alt="news-latters"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Home;
